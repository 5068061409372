/*
// .departments
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

$local-transition-duration: 0.25s;

.departments {
  width: 255px;
  margin-top: 8px;
  color: #fff;
  position: relative;
  margin-left: -3.7rem !important;
}
.departments__button {
  border-radius: none;
  border: none;
  background-color: $accent-color;
  color: white;
  font-size: 1rem;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: default;
  font-weight: $font-weight-medium;
  padding: 0;
  margin-left: 50px;

  @include direction {
    #{$padding-inline-end}: 32px;
    #{$padding-inline-start}: 60px;
    text-align: $inline-start;
  }

  &:focus {
    outline: none;
  }
}

.departments__button-text {
  font-size: 1rem;
}

.departments__button-icon {
  position: absolute;
  top: calc(50% - 7px);
  fill: white;
  transition: fill 0.2s;

  @include direction {
    #{$inset-inline-start}: 16px;
  }
}
.departments__button-arrow {
  position: absolute;
  top: calc(50% - 3px);
  fill: $secondary-color;
  transition: fill 0.2s, transform 0.2s;

  @include direction {
    #{$inset-inline-end}: 13px;
  }
}
.departments__body {
  width: 100%;
  padding-top: none;
  position: absolute;
  background: white;
  box-shadow: $departments-shadow;
  border-radius: 2px;
  color: #0d597d;
  margin-top: 42px;
  margin-left: 20px;
  top: 0;
}
.departments__links {
  position: relative;

  // reset list
  list-style: none;
  padding: 0 0 14px;
  margin: 6px 0 0;

  > li {
    &:hover .departments__menu,
    &:hover .departments__megamenu {
      visibility: visible;
    }
    &:hover > a {
      background: $departments-item-hover-bg;
    }
  }
  > li > a {
    font-weight: $departments-font-weight;
    position: relative;
    display: block;
    padding: 10px 16px;
    color: inherit;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
}
.departments__item--menu {
  position: relative;
}

.departments_item--menu:hover {
  color: white;
  background: #0d597d;
}

.departments__links > li > a:hover {
  color: white;
  background: #0d597d;
}

.departments__link-arrow:hover {
  fill: white;
}

.departments__link-arrow {
  top: calc(50% - 5px);
  position: absolute;
  fill: $secondary-color;

  @include direction {
    #{$inset-inline-end}: 14px;
    transform: scaleX(1 * $transform-direction);
  }
}
.departments__megamenu {
  position: absolute;
  top: 0;
  height: 100%;
  visibility: hidden;

  @include direction {
    #{$inset-inline-start}: 100%;
  }
}
.departments__menu {
  position: absolute;
  top: 0;
  height: 100%;
  visibility: hidden;

  @include direction {
    #{$inset-inline-start}: 100%;
  }
}

.departments__megamenu--sm {
  width: (206px * 1 + 31px);
}
.departments__megamenu--nl {
  width: (206px * 2 + 31px);
}
.departments__megamenu--lg {
  width: (206px * 3 + 31px);
}
.departments__megamenu--xl {
  width: (206px * 4 + 31px);
}

.departments__links-wrapper {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height $local-transition-duration ease-in-out,
    opacity $local-transition-duration ease-in-out;
}

.departments:not(.departments--fixed) {
  .departments__button {
    cursor: pointer;

    &:hover {
      .departments__button-icon,
      .departments__button-arrow {
        fill: currentColor;
      }
    }
  }
}
.departments--fixed {
  .departments__button {
  }
}
.departments--opened {
  .departments__links-wrapper {
    overflow: visible;
    height: auto;
    opacity: 1;
  }
  .departments__button-arrow {
    transform: rotateZ(180deg);
  }
}
.departments--transition {
  .departments__links-wrapper {
    overflow: hidden;
  }
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
  .departments {
    width: 210px;
  }
  .departments__megamenu--sm {
    width: (172px * 1 + 32px);
  }
  .departments__megamenu--nl {
    width: (172px * 2 + 32px);
  }
  .departments__megamenu--lg {
    width: (172px * 3 + 32px);
  }
  .departments__megamenu--xl {
    width: (172px * 4 + 32px);
  }
}
