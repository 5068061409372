@import "../variables";

.pricing-table-main {
  min-height: 150px;
  //min-width: 600px;
  //box-shadow: $box-shadow-global;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0.5% 1%;
  align-items: center;
  border-radius: 8px;
}
.pricing-table-main-heading {
  font-size: 1.2rem;
  width: 100%;
  text-align: left;
  //padding-left: 2%;
  text-transform: capitalize;
  margin-top: 20px;

  @media screen and (max-width: $sm) {
    text-align: center;
  }
}

.pricing-table-main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 100%;
  min-height: 100%;
  justify-content: space-between;

  @media screen and (max-width: $sm) {
    width: 80%;
    margin-left: auto;
    margin-lright: auto;
  }
}

.pricing-table-main-item {
  margin: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.pricing-table-main-item-price-text {
  color: $accent-color;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pricing-table-main-item-duration-text {
  color: $accent-color;
  font-weight: 100;
  font-size: 0.9rem;
}
