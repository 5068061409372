/*
// .product-image
*/
@import "src/scss/themes/MyTheme";
@import "../variables";
@import "../mixins/hacks";

.product-image {
}
.product-image__body {
  display: block;
  position: relative;
  padding-bottom: #{100% * (1 / $product-image-ratio)};
  width: 100%;
}

.new-product-image-div {
  height: 300px;
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.product-details-main {
  display: flex;
  flex-direction: column;
}

.detail-product-div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: auto;
  margin-top: 0.5rem;
  //width:300px;
}

@media (max-width: 800px) {
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 2.5rem;
  width: 300px;
}

.product-det-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //text-align: left;
  //    align-items: start;
  //    justify-content: start;
}

.product-det-list li {
  font-size: 12px;
  color: #0d597d;
  margin-top: 0.7rem;
  font-weight: 600;
}
.product-page-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product-page-main-mob {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.prod-gallery-main {
  width: 350px;
}

@media (max-width: 800px) {
  .prod-gallery-main {
    width: 250px;
  }
}

.right-most-product-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 300px;
}

@media (max-width: 800px) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 300px;
}

.right-most-product-container {
  height: 230px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //box-shadow:   $box-shadow-global;
  //padding: 10%;
  border-radius: 12px;
  //margin-top: 400px;
}

.making-flex-rows {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.right-most-product-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 60px;
}

.product-image__img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &,
  .owl-carousel .owl-item & {
    @include only-ie {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
.product-image--location--gallery {
  .owl-carousel .owl-item & .product-image__img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.product-image-top-level {
  height: 22vw;
  width: 12vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: $sm) {
    width: 50vw;
    height: 300px;
  }
}

.product-image-first-level {
  width: $thumbnail;
  height: $thumbnail;

  margin: 80px 15px 10px;
  padding: 15px;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  pointer-events: none;

  border-radius: 20px;

  img {
    width: $thumbnail;
    height: $thumbnail;

    border-radius: 8px;

    object-fit: cover;
  }
}

.product-image-low-level {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 200px !important;
  text-align: center;
  margin-top: 4px;

  h1 {
    text-transform: capitalize;
    font-size: 1.2rem;
    color: $accent-color;
    font-weight: 500;
  }

  .product-card-title {
    //text-overflow: ellipsis;
    //white-space: nowrap;
    //max-width: 100%;
    //max-height: 30%;
    //display: inline-block;
    //overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.product-price {
  margin-top: 12px;
}

.product-rating-div {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}

.product-image-top-level-blurred {
  height: 310px;
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgb(230, 230, 230);
  filter: blur(2px);
  -webkit-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.1);
}

.product-image-top-level-most-rented {
  height: 310px;
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgb(230, 230, 230);
  margin-left: 0.5rem;
}
