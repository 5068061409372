/*
// .block-products-carousel
*/
@import "../variables";
@import "../functions";
@import "../mixins/product-card";
@import "../mixins/preloader";

.block-products-carousel {
  .slick-track {
    padding-bottom: 56px;
    display: flex;
    align-items: stretch;
  }
  .slick-list {
    margin: 0 auto -56px auto;
    //margin-bottom: -56px;

    padding: 12px 0 50px 20px;

    &:hover {
      z-index: 2;
    }
  }
  .slick-slide {
    padding: 0 5px;
    width: 280px !important;
    height: auto;
    display: flex;

    &:focus,
    .correct-slick-active {
      outline: none;
    }

    & > div,
    & > div > div {
      width: 100%;
      display: flex !important;
      align-items: stretch;
    }
  }
}

.block-products-carousel-btn {
  color: #0d597d;
  font-weight: 600;
  font-size: 1rem;
  text-align: right;
  margin-top: 5rem;
}

.block-products-carousel__slider {
  position: relative;
  min-height: 200px;

  @media screen and (max-width: $sm) {
    width: 60% !important;
  }
}

.block-products-carousel__preloader {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $block-products-carousel-preloader-bg;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s;

  &:after {
    @include preloader(100px);
  }
}
.block-products-carousel--loading .block-products-carousel__preloader {
  transition-delay: 0s, 0s;
  opacity: 1;
  visibility: visible;
}
.block-products-carousel__column {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  margin-bottom: 2rem;

  @media screen and (max-width: $sm) {
    flex-direction: row;
  }
}

.slider-nav-button {
  @media screen and (max-width: $sm) {
    display: none !important;
  }
}

.block-products-carousel__cell {
  width: 100%;
  flex-grow: 1;
  display: flex;

  & + & {
    margin-top: 10px;
  }

  .product-card {
    width: 100%;
  }

  @media screen and (max-width: $sm) {
    //width: 50vw;
    //height: 25vw;
  }
}

.block-products-carousel--has-items {
  .block-products-carousel__preloader {
    bottom: 56px;
  }
}

.block-products-carousel[data-layout^="grid-"] {
  .product-card {
    @include product-card-grid();
  }
}
.block-products-carousel[data-layout="grid-4"] {
  .product-card {
    @include product-card-grid-nl();
  }

  @media (min-width: 480px) and (max-width: breakpoint(lg-end)) {
    .product-card {
      @include product-card-grid-sm();
    }
  }
}
.block-products-carousel[data-layout="grid-4-sm"] {
  @media (min-width: 480px) {
    .product-card {
      @include product-card-grid-sm();
    }
  }
  @media (max-width: 479px) {
    .product-card {
      @include product-card-grid-nl();
    }
  }
}
.block-products-carousel[data-layout="grid-5"] {
  @media (min-width: 480px) {
    .product-card {
      @include product-card-grid-sm();
    }
  }
  @media (max-width: 479px) {
    .product-card {
      @include product-card-grid-nl();
    }
  }
}

.block-products-carousel[data-layout="horizontal"] {
  .product-card {
    @include product-card-horizontal();
  }
}
