// colors


$light-opposite-color: #3d464d !default;
$accent-color: #0d597d !default;
$secondary-color: #fdc126 !default;

$accent-opposite-color: #fff !default;
$link-color: $accent-color !default;
$link-hover-color: $accent-color !default;

$text-color: #1b1c1e !default;
$text-inverted-color: #f6f6f6 !default;

// .nav-links
$nav-links-item-font-weight: 400 !default;
$nav-links-item-default-arrow-color: rgba(#000, 0.25) !default;
$nav-links-item-hover-bg: rgba(#fff, 0.15) !default;

// .departments
$departments-bg: $secondary-color !default;
$departments-icon-color: rgba(#fff, 0.4) !default;
$departments-arrow-color: rgba(#fff, 0.4) !default;
$departments-item-hover-bg: rgba(#fff, 0.08) !default;

// .mobile-header
$mobile-header-menu-button-hover-bg: $nav-links-item-hover-bg !default;
// search input default
$mobile-header-search-input-default-bg: rgba(#fff, 0.4) !default;
$mobile-header-search-input-default-shadow: none !default;
$mobile-header-search-input-default-placeholder: rgba(#000, 0.5) !default;
$mobile-header-search-input-default-font-color: $light-opposite-color !default;
$mobile-header-search-input-default-icon-color: rgba(#000, 0.4) !default;
$mobile-header-search-input-default-icon-hover-color: $light-opposite-color !default;
// search input hover
$mobile-header-search-input-hover-bg: rgba(#fff, 0.6) !default;
// search input focus
$mobile-header-search-input-focus-bg: #fff !default;
$mobile-header-search-input-focus-shadow: 0 1px 5px rgba(#000, 0.2) !default;
$mobile-header-search-input-focus-placeholder: #999 !default;
$mobile-header-search-input-focus-font-color: $light-opposite-color !default;
$mobile-header-search-input-focus-icon-color: #b3b3b3 !default;
$mobile-header-search-input-focus-icon-hover-color: $light-opposite-color !default;

// .product-card
$product-card-hover-shadow: 0 0 0 2px #e5e5e5 inset !default;
$product-card-quickview-default-bg: #e5e5e5 !default;
$product-card-quickview-default-color: $light-opposite-color !default;

// .nouislider
$nouislider-border-color: $accent-color !default;
$nouislider-handle-color: $accent-opposite-color !default;

// .breakpoints
$xxl: 1536px !default;
$xl: 1280px !default;
$lg: 1024px !default;
$md: 768px !default;
$sm: 640px !default;

