/*
// .widget-tags
*/
@import "../variables";
@import "../mixins/card";

.widget-tags {
  @include card();

  padding: $widget-blog-padding;

  @media (max-width: 479px) {
    padding: 20px;
  }
}
