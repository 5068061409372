/*
// .widget-aboutus
*/
@import "../variables";
@import "../mixins/card";

.widget-aboutus {
  @include card();

  padding: 28px;

  @media (max-width: 479px) {
    padding: 20px;
  }
}
.widget-aboutus__socials {
  display: block;
  margin-top: 20px;
}
