/*
// .search--location--header
*/
@import "../variables";
@import "../svg";
@import "../mixins/direction";

$local-suggestion-transition: 0.2s;

@mixin local-input-focus-state() {
  @if ($header-type == white) {
    .search__input:focus {
      @content;
    }
  } @else {
    .search__input:focus,
    &.search--has-suggestions.search--suggestions-open .search__input {
      @content;
    }
  }
}

.search--location--header {
  .search__body {
    position: relative;
    z-index: 2;
    background-color: white;
  }
  .search__form {
    height: $header-search-height;
    display: flex;
  }
  .search__border {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $header-search-border-radius;
    transition: box-shadow 0.15s, background 0.15s;
  }

  .search__categories &:focus {
    outline: none;
  }

  .search__location_text {
    background-color: transparent;
    height: 100%;
    border: none;
    outline: none;
    width: 100%;

    //&::placeholder{
    //    color: black;
    //}
  }

  .search__location_text::placeholder {
    text-transform: capitalize !important;
  }

  .search__category__option-item {
    margin-bottom: 4px;
    margin-left: 4px;
    font-weight: 400;
    padding: 2px;
    font-size: 0.8rem;
    border-bottom: 1px solid #eeeeee;
    width: 95%;

    &:hover {
      color: white;
      background: #0d597d;
    }
  }

  .search__category__dropdown {
    margin-left: -20px;
    background-color: white;
    margin-top: -2px;
    width: 200px;
    padding: 10px;
    text-align: left;
  }

  .search__category__dropdown li {
    padding: 5px;
  }

  .search__category__dropdown li:hover {
    background: #0d597d;
    color: white;
  }

  .search-filter-border {
    background-color: $header-search-input-default-border-color;
    width: 2px;
    opacity: 0.4;
    height: 60%;
    margin-left: 12px;
  }

  .search__location {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    //margin: 2px;
    appearance: none;
    border: none;
    background-color: white;
    color: $header-search-input-default-font-color;
    font-size: 14px;
    transition: background-color 0.2s;
    max-width: 170px;
    cursor: pointer;
    border-radius: 0;

    &:focus {
      outline: none;
    }
  }

  .search__categories {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    //margin: 2px;
    padding: 0 14px;
    appearance: none;
    border: none;
    background-color: white;
    color: $header-search-input-default-font-color;
    font-size: 14px;
    transition: background-color 0.2s;
    max-width: 175px;
    cursor: pointer;
    border-radius: 0;

    //@include direction {
    //    #{$border-inline-end}: 2px solid $header-search-input-default-border-color;
    //    #{$padding-inline-end}: 20px;
    //    #{$margin-inline-end}: 0;
    //    background-position: center $inline-end 13px;
    //}

    //&:hover {
    //    background-color: #f5f5f5;
    //}
    &:focus {
      outline: none;
    }
  }

  .search__input {
    font: $body-font-family;
    border: none;
    padding: 0 17px;
    background: transparent;
    width: 1px;
    flex-grow: 1;
    font-weight: 100;
    font-size: 0.9rem;
    transition: color 0.15s;
    color: $header-search-input-default-font-color;

    &::placeholder {
      color: $header-search-input-default-placeholder;
    }
    & ~ .search__border {
      /* offset-x | offset-y | blur-radius | spread-radius | color */
      box-shadow: $box-shadow-search;
      border: none;
      border-radius: 12px;
      //background: $header-search-input-default-bg;
      //box-shadow: $header-search-input-default-shadow;
    }
    &:focus {
      outline: none;
    }
  }
  .search__input:hover {
    color: $header-search-input-hover-font-color;

    &::placeholder {
      color: $header-search-input-hover-placeholder;
    }
    & ~ .search__border {
      //background: $header-search-input-hover-bg;
      //box-shadow: $header-search-input-hover-shadow;
    }
    & ~ .search__button {
      fill: $header-search-input-hover-icon-color;

      &:hover {
        fill: $header-search-input-hover-icon-hover-color;
      }
    }
  }
  @include local-input-focus-state {
    outline: none;
    //color: $header-search-input-focus-font-color;

    &::placeholder {
      //color: $header-search-input-focus-placeholder;
    }
    & ~ .search__border {
      //background: $header-search-input-focus-bg;
      //box-shadow: $header-search-input-focus-shadow;
    }
    & ~ .search__button {
      //fill: $header-search-input-focus-icon-color;

      &:hover {
        //fill: $header-search-input-focus-icon-hover-color;
      }
    }
  }
  .search__button {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    padding: 0;
    z-index: 10;
    border: 1px solid #0d597d;
    background: #0d597d;
    flex-grow: 0;
    width: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: white;
    transition: fill 0.15s;

    //&:hover,
    //&:focus {
    //    outline: none;
    //    fill: white;
    //}
  }

  @if ($header-type == white) {
    .search__suggestions {
      $local-expand: 1.1rem;
      $local-padding-top: 0.5rem;
      $local-padding-bottom: 10px;

      z-index: -1;
      position: absolute;
      //box-shadow: $header-search-suggestions-shadow;
      background: $header-search-suggestions-bg;
      //left: -$local-expand;
      top: -$local-expand;
      width: calc(90% + #{$local-expand * 2});
      padding-top: $header-search-height + $local-expand + $local-padding-top;
      padding-bottom: $local-padding-bottom;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: opacity $local-suggestion-transition,
        visibility 0s $local-suggestion-transition;
    }
    &.search--has-suggestions.search--suggestions-open {
      .search__suggestions {
        transition-delay: 0s, 0s;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  } @else {
    display: block;
    height: $header-search-height;

    .search__border {
      z-index: -1;
    }
    .search__body {
      display: flex;
      flex-direction: column;
    }
    .search__categories {
      margin: 0;

      @include direction {
        #{$border-start-start-radius}: $header-search-border-radius;
        #{$border-end-start-radius}: $header-search-border-radius;
        #{$border-inline-end}: 1px solid
          $header-search-input-default-border-color;
      }

      &:hover {
        //background-color: rgba(#000, .05);
      }
    }
    .search__suggestions {
      $local-padding-top: 6px;
      $local-padding-bottom: 10px;

      left: 0;
      top: 0;
      width: calc(100%);
      border-top: 1px solid $header-search-suggestions-divider-color;
      padding-top: $local-padding-top;
      padding-bottom: $local-padding-bottom;
      display: none;
    }
    &.search--has-suggestions.search--suggestions-open {
      .search__suggestions {
        display: block;
      }
    }
  }
}

.ul-search-category {
  margin-left: -38px !important;
  border-radius: 2px !important;
}
