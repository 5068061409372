/*
// .mobile-header
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.mobile-header {
  height: $nav-panel-height;
}
.mobile-header__panel {
  z-index: 10;
  position: relative;
  background: $nav-panel-bg;
  box-shadow: $nav-panel-shadow;
  color: $nav-panel-font-color;
  position: relative;
}
.mobile-header__body {
  display: flex;
  align-items: center;
  height: $nav-panel-height;
}
.mobile-header__menu-button {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: $mobile-header-menu-button-border-radius;
  background: $mobile-header-menu-button-default-bg;
  fill: #1b1c1e;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: fill 0.2s, background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background: $mobile-header-menu-button-hover-bg;
    fill: $mobile-header-menu-button-hover-color;
  }
}

.mobile-header__search {
  //background: $offcanvas-search-bg;
  z-index: 1;

  width: 80%;
  height: 2.5rem;

  border-radius: 8px;

  .search__input {
    width: 100%;
    height: 4ch;

    vertical-align: center !important;
  }

  .search__button--type--submit {
    height: 4ch !important;
  }

  .search__suggestions {
    background: #ffffff;
    width: 100vw;
    left: 0;
    right: 0;
    padding-top: 0!important;
    position: absolute;
    top: 60px;
  }

  .search__categories { display: none !important; }

  .search__location { display: none !important; }

  .search__button--type--close { display: none !important; }
}

.mobile-header__indicators {
  display: flex;

  @include direction {
    #{$margin-inline-start}: auto;
  }
}
.mobile-header__logo {
  margin-top: 8px;
  color: inherit;
  width: 80%;
  align-content: center;
  fill: currentColor;

  @include direction {
    #{$margin-inline-end}: 16px;
    #{$margin-inline-start}: 12px;
  }

  &:hover {
    color: inherit;
  }

  svg {
    display: block;
  }
}
