/*
// .related-posts
*/
@import "../variables";

.related-posts {
}
.related-posts__list {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}
.related-posts__item {
  width: calc(50% - #{15px * 2});
  margin: 15px;
}

@media (max-width: 479px) {
  .related-posts__item {
    width: calc(100% - #{15px * 2});
  }
}
