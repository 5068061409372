/*
// .block-product-columns
*/
@import "../variables";
@import "../functions";
@import "../mixins/product-card";

.block-product-columns {
}
.block-product-columns__item {
  position: relative;

  & + & {
    margin-top: -1px;
  }

  &:hover {
    z-index: 2;
  }
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
  .block-product-columns .product-card {
    @include product-card-horizontal-sm();
  }
}
