@import "../variables";
@import "../functions";

.icon-card {
  h4 {
    font-size: 1.4rem;
  }

  @media screen and (max-width: $sm) {
    width: 28vw!important;
    height: 28vw!important;

    h4 {
      font-size: 1.2rem;
    }
  }
}

.icon-card-text {
  @media screen and (max-width: $sm) {
    display: none;
  }
}