/*
// .product
*/
@import "../variables";
@import "../functions";
@import "../mixins/hacks";
@import "../mixins/direction";

.product--layout {
  margin: 0 0 2%;
  //width: 50%;
}
.product {
}
.product__content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
  grid-template-columns: [gallery] calc(50% - 20px) [info] calc(50% - 20px);
  grid-template-rows: auto auto auto auto auto;
  grid-column-gap: 40px;
}
.product__gallery {
  -ms-grid-row: 1;
  -ms-grid-row-span: 6;
  -ms-grid-column: 1;

  @include only-ie() {
    @include direction {
      #{$margin-inline-end}: 40px;
    }
  }

  grid-row-start: 1;
  grid-row-end: 6;
  min-height: 0;
}
.product__info {
  -ms-grid-row: 1;
  -ms-grid-column: 2;

  position: relative;
  min-height: 0;
}
.product__sidebar {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
}
.product__footer {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
}
.product__wishlist-compare {
  display: none;
}
.product__name {
  margin-bottom: 18px;
}
.product__rating {
  display: flex;
}
.product__rating-stars {
  @include direction {
    #{$margin-inline-end}: 12px;
  }
}
.product__rating-legend {
  font-size: 14px;
  line-height: 20px;
  color: $rating-legend-font-color;
  margin-bottom: 16px;

  a {
    color: inherit;
    transition: color 0.2s;
  }
  a:hover {
    color: $link-hover-color;
  }

  span {
    content: "/";
    padding: 0 7px;
  }
}
.product__description {
  font-size: 16px;
}
.product__features {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;

  li {
    padding-top: 1px;
    padding-bottom: 1px;
    position: relative;

    @include direction {
      #{$padding-inline-start}: 13px;
      #{$padding-inline-end}: 0;
    }
  }
  li::before {
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    border: 1px solid currentColor;

    @include direction {
      #{$inset-inline-start}: 0;
    }
  }
}
.product__meta {
  list-style: none;
  margin: 12px 0 0;
  padding: 12px 0 0;
  display: flex;
  flex-wrap: wrap;
  color: $body-font-muted-color;
  font-size: 14px;
  border-top: 1px solid $body-divider-color;

  li {
    @include direction {
      #{$margin-inline-end}: 18px;
    }
  }

  a {
    color: inherit;
    transition: color 0.2s;
  }
  a:hover {
    color: $link-hover-color;
  }
}
.product__availability {
  display: none;
}
.product__prices {
  margin-top: 18px;
  margin-bottom: 14px;
  font-size: 28px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.03em;
  color: $price-color;
}
.product__new-price {
  color: $price-new-color;
}
.product__old-price {
  color: $price-old-color;
  font-weight: $font-weight-normal;
  font-size: 20px;
  text-decoration: line-through;
}
.product__option {
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}
.product__option-label {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  color: $body-font-alt-color;
  padding-bottom: 2px;
}
.product__actions {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}
.product__actions-item {
  margin: 4px;
}
.product__quantity {
  width: 120px;
}
.product__footer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.product__tags {
  @include direction {
    #{$margin-inline-end}: 24px;
    #{$margin-inline-start}: 0;
  }
}
.product__share-links {
  margin-top: 2px;
  margin-bottom: 2px;
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
  .product__name {
    font-size: 28px;
    margin-bottom: 18px;
  }
}
@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
  .product__content {
    -ms-grid-columns: 44% 56%;
    grid-template-columns: [gallery] calc(44% - 15px) [info] calc(56% - 15px);
    grid-column-gap: 30px;
  }
  .product__gallery {
    @include only-ie() {
      @include direction {
        #{$margin-inline-end}: 30px;
      }
    }
  }
  .product__name {
    font-size: 24px;
    margin-bottom: 18px;
  }
  .product__footer {
    display: block;
    margin-top: 18px;
  }
  .product__share-links {
    margin: 12px 0 0;
  }
}
@media (max-width: breakpoint(sm-end)) {
  .product__content {
    display: block;
  }
  .product__gallery {
    margin-bottom: 24px;
  }
  .product__name {
    font-size: 28px;
    margin-bottom: 18px;
  }
  .product__footer {
    margin-top: 22px;
  }
}
@media (max-width: 559px) {
  .product__footer {
    display: block;
    margin-top: 22px;
  }
  .product__share-links {
    margin: 12px 0 0;
  }
}

@import "product--layout--columnar";

.product--layout--sidebar {
  @media (min-width: breakpoint(lg-start)) {
    .product__content {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
      grid-column-gap: 32px;
    }
    .product__gallery {
      @include only-ie() {
        @include direction {
          #{$margin-inline-end}: 32px;
        }
      }
    }
    .product__name {
      font-size: 24px;
      margin-bottom: 12px;
    }
    .product__footer {
      display: block;
      margin-top: 18px;
    }
    .product__share-links {
      margin: 12px 0 0;
    }
  }
}

.product--layout--quickview {
  @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .product__content {
      grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
      grid-column-gap: 32px;
    }
    .product__name {
      margin-bottom: 12px;
    }
    .product__footer {
      display: block;
      margin-top: 18px;
    }
    .product__share-links {
      margin: 12px 0 0;
    }
  }
  @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .product__content {
      display: block;
    }
    .product__gallery {
      margin-bottom: 24px;
    }
    .product__name {
      font-size: 24px;
      margin-bottom: 18px;
    }
  }
}

.product--layout-information-heading {
  font-size: 2rem;
  color: $accent-color;
  font-weight: 800 !important;
  width: 100%;
  text-align: left;
  padding: 2% 5% 0;
  text-transform: capitalize;

  @media screen and (max-width: $sm) {
    text-align: center;
  }
}
.product--layout-information-tool {
  margin: 0 4%;
}

//.product--layout-information {
//  display: flex;
//  flex-direction: row;
//  justify-content: space-around;
//  //align-items: center;
//  min-width: 100%;
//}

.product-date-picker {
  padding: 2%;
  margin-top: 5%;
  box-shadow: $box-shadow-global;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hr {
    color: black;
  }

  //height: 400px;
}

.product--layout-information-tool-item {
  font-weight: 500;
  color: gray;

  .tool-name {
    font-size: 1.4rem!important;
  }

}

.MuiPickersDay-root.Mui-selected {
  background-color: $accent-color !important;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: rgba(13, 89, 125, 0.8) !important;
}

.MuiPickersStaticWrapper-root {
}

.MuiPickersDay-root.Mui-disabled {
  opacity: 0.6;
}

// NOTE: the order of these styles DO matter
//
//.DayPickerNavigation_button__default {
//  border: none;
//  &:hover {
//    border: none;
//  }
//}
//
//.CalendarDay__default {
//  border: none;
//  //border-radius: 50%;
//  //padding: 0 8px 0;
//}
//
//.CalendarMonth_table {
//  border-spacing: 10px !important;
//}
//// Will edit everything selected including everything between a range of dates
//.CalendarDay__selected_span {
//  background: $accent-color; //background
//  color: white; //text
//  border: none;
//
//  //border: 1px solid $accent-color; //default styles include a border
//}
//
//.CalendarMonth_table {
//  .tr {
//    margin: 8px !important;
//  }
//}
//
//.CalendarDay__selected_span {
//  background: $accent-color; //background
//  color: white; //text
//  border: none;
//
//  &:hover {
//    border: none;
//    background-color: $secondary-color;
//  }
//
//  //border: 1px solid $accent-color; //default styles include a border
//}
//
//// Will edit selected date or the endpoints of a range of dates
//.CalendarDay__selected {
//  background: $accent-color;
//  color: white;
//  border: none;
//
//  &:hover {
//    border: none;
//    background-color: $secondary-color;
//  }
//}
//.CalendarDay__selected:active{
//  border: none;
//}
//
//.CalendarDay__hovered_span{
//  border: none;
//}
//// Will edit when hovered over. _span style also has this property
//.CalendarDay__selected:hover {
//  background: $accent-color;
//  color: white!important;
//  border: none!important;
//
//}
//.CalendarDay__blocked_calendar {
//  opacity: 0.4;
//  border: none;
//}
//
//// Will edit when the second date (end date) in a range of dates
//// is not yet selected. Edits the dates between your mouse and said date
//.CalendarDay__hovered_span:hover,
//.CalendarDay__hovered_span {
//  background: $accent-color;
//}
