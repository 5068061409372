@import "../variables";
@import "../functions";
.home-banner {
  width: 100%;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-banner-title {
  font-size: 1.6rem;
  font-weight: 800;

  color: $secondary-color;
}

.home-banner-subtitle {
  font-size: 1.6rem;
  font-weight: 100;

  color: $secondary-color;
}

.home-page-projects {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  padding: 10%;
  min-height: 15rem;
}
.home-page-projects-image {
  height: 20rem;
  object-fit: contain;
}

.home-banner-projects-text {
  text-align: center;
  color: $accent-color;
}
.home-banner-projects-title {
  font-weight: 800;
  font-size: 2rem;
  font-family: Roboto;
}
.home-banner-projects-subtitle {
  font-weight: 100;
  font-size: 1.6rem;
  font-family: Roboto;
}
