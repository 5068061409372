/*
// .account-menu
*/
@import "../variables";
@import "../mixins/hacks";
@import "../mixins/direction";

.account-menu {
  box-shadow: $account-menu-shadow;
  background: $account-menu-bg;
  color: $account-menu-font-color;
  width: 280px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  // without it IE renders a blurred menu
  @include only-ie() {
    box-shadow: 0 0 0 1px rgba(#000, 0.15);
  }
}
.account-menu__form {
  padding: 0 30px 32px;

  .form-group {
    margin-bottom: 1rem;
  }
}
.account-menu__form-title {
  text-align: center;
  padding: 30px 0 26px;
  font-weight: $font-weight-bold;
}
.account-menu__form-button {
  margin-top: 32px;
  text-align: center;

  .btn {
    padding-left: 28px;
    padding-right: 28px;
  }
}
.account-menu__form-link {
  font-size: 14px;
  text-align: center;

  a {
    color: $account-menu-font-alt-color;
    transition: color 0.1s;
  }
  a:hover {
    color: inherit;
  }
}
.account-menu__form-forgot {
  position: relative;
}
.account-menu__form-forgot-link {
  $local-offset: 5px;

  position: absolute;
  top: $local-offset;
  bottom: $local-offset;
  border-radius: 2px;
  font-size: 12px;
  font-weight: $font-weight-medium;
  background: $btn-light-bg-color;
  color: $btn-light-font-color;
  display: flex;
  align-items: center;
  padding: 0 7px;
  transition: background 0.1s, color 0.1s;

  @include direction {
    #{$inset-inline-end}: $local-offset;
  }

  &:hover {
    background: $btn-light-hover-bg-color;
    color: $btn-light-hover-font-color;
  }
  &:active {
    background: $btn-light-active-bg-color;
    color: $btn-light-active-font-color;
  }
}
.account-menu__divider {
  height: 1px;
  background: $account-menu-divider-color;
}
.account-menu__user {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  color: inherit;

  &:hover {
    color: inherit;
    background: $account-menu-item-hover-bg;
  }
}
.account-menu__user-avatar {
  width: 44px;
  flex-shrink: 0;

  @include direction {
    #{$margin-inline-end}: 14px;
  }

  img {
    border-radius: 100%;
    max-width: 100%;
  }
}
.account-menu__user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.account-menu__user-name {
  font-size: 15px;
  line-height: 20px;
  font-weight: $font-weight-medium;
}
.account-menu__user-email {
  font-size: 14px;
  line-height: 18px;
  color: $account-menu-font-muted-color;
  margin-top: 1px;
}
.account-menu__links {
  list-style: none;
  padding: 12px 0;
  margin: 0;

  a {
    display: block;
    color: inherit;
    font-size: 15px;
    padding: 5px 20px;
    font-weight: $font-weight-medium;
  }
  a:hover {
    background: $account-menu-item-hover-bg;
  }
}

.account-header-top-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
.acc-balance-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 1%;
}
