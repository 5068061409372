/*
// .suggestions
*/
@import "../variables";
@import "../mixins/direction";

.suggestions {
}
.suggestions__list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 19px;
}
.suggestions__item {
  position: relative;
  display: flex;
  padding: 8px 18px;

  &:before {
    z-index: 1;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
.suggestions__item-image {
  flex-shrink: 0;
  width: 44px;
  margin: -2px 0;

  @include direction {
    #{$margin-inline-end}: 12px;
  }
}
.suggestions__item-info {
  flex-grow: 1;
  padding: 3px 0 2px;
}
.suggestions__item-name {
  &,
  &:hover {
    color: inherit;
  }

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.suggestions__item-meta {
  margin-top: 1px;
  font-size: 13px;
  line-height: 15px;
}
.suggestions__item-price {
  white-space: nowrap;
  flex-shrink: 0;
  padding: 3px 0 2px;
  font-weight: $font-weight-bold;
  font-size: 14px;

  @include direction {
    #{$margin-inline-start}: 12px;
  }
}
.suggestions__item-actions {
  align-self: center;
  flex-shrink: 0;
  position: relative;
  z-index: 2;

  @include direction {
    #{$margin-inline-start}: 14px;
  }
}

.suggestions--location--header {
  color: $header-search-suggestions-font-color;

  .suggestions__item:hover:before {
    background: $header-search-suggestions-item-hover-bg;
  }
  .suggestions__item-meta {
    color: $header-search-suggestions-muted-font-color;
  }
}
.suggestions--location--indicator {
  color: $indicator-search-suggestions-font-color;

  .suggestions__item:hover:before {
    background: $indicator-search-suggestions-item-hover-bg;
  }
  .suggestions__item-meta {
    color: $indicator-search-suggestions-muted-font-color;
  }
  .suggestions__item-actions {
    display: none;
  }
}
.suggestions--location--mobile-header {
  color: $mobile-header-search-suggestions-font-color;

  .suggestions__item:hover:before {
    background: $mobile-header-search-suggestions-item-hover-bg;
  }
  .suggestions__item-meta {
    color: $mobile-header-search-suggestions-muted-font-color;
  }
  .suggestions__item-actions {
    display: none;
  }
}
