/*
// .block-categories
*/
@import "../variables";
@import "../functions";

$local-item-margin: 6px;

.block-categories {
}
.block-categories__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: -$local-item-margin;
}
.block-categories__item {
  margin: $local-item-margin;
  width: calc(33.3333% - #{$local-item-margin * 2});
}

.block-categories--layout--classic {
  @media (min-width: 480px) and (max-width: breakpoint(sm-end)) {
    .block-categories__item {
      width: calc(50% - #{$local-item-margin * 2});
    }
  }
  @media (max-width: 479px) {
    .block-categories__item {
      width: calc(100% - #{$local-item-margin * 2});
    }
  }
}

.block-categories--layout--compact {
  @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .block-categories__item {
      width: calc(50% - #{$local-item-margin * 2});
    }
  }
  @media (max-width: breakpoint(sm-end)) {
    .block-categories__item {
      width: calc(100% - #{$local-item-margin * 2});
    }
  }
}

.block-cat-main {
  padding: 5%;
  //display: flex;
  //flex-wrap: wrap;
  //flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vw;

  //box-shadow: 0 0 12px 10px rgba(0, 0, 0, 0.02);
  //border-radius: 8px;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 300px;
    width: 100%;
    padding: 5%;
    border: 1px solid rgb(230, 230, 230);
    margin-top: 1rem;
    margin-bottom: 16px;
  }

  @media screen and (max-width: $sm) {
    width: 95%;
  }
}

.block-cat-main-image {
  width: 150px;
  height: 150px;
  //border-radius: 999px;
  border-radius: 10px;
  object-fit: cover;
}

.block-cat-bottom {
  //width: 200px;
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;

  @media screen and (max-width: $sm) {
    font-size: 1.5rem;
  }
}

.block-cat-bottom a {
  font-weight: 600 !important;
}
