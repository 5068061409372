/*
// .site-header
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.site-header {
  //display: flex;
  //flex-direction: row;
  //justify-content: space-evenly;
  //width: 100%;
  position: relative;
  z-index: 100;
  background: $header-bg;
  color: $header-font-color;
}
.site-header__middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  margin-right: 5rem!important;
  margin-left: 5rem!important;
  padding: 2rem 2rem 2rem 2rem;
}

.site-header__post_tool {
  width: 120px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.site-header__post_tool a {
  cursor: pointer;
  color: black !important;
}

.site-header__logo_img {
  width: 10rem;
}

.site-header-post-btn {
  //background-color: $accent-color;
  padding: 8px 8px;
  text-align: center;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;
  color: $accent-color;
  font-weight: 600;
}

.site-header__logo {
  flex-shrink: 0;
  width: 185px;
  //margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $header-logo-color;

  //@include direction {
  //  #{$margin-inline-end}: 30px;
  //}

  a {
    color: inherit;
  }

  svg {
    fill: currentColor;
  }
}
.site-header__search {
  width: 62%;

  margin: 16px;
}
.site-header-login {
  margin-left: 12px;
  font-weight: 600;
  //margin-top: 6px;
  color: $accent-color;
  cursor: pointer;
  font-size: 1rem;
}

.site-header__phone {
  line-height: 1;
  flex-shrink: 0;

  @include direction {
    text-align: $inline-end;
    #{$margin-inline-start}: 80px;
  }
}
.site-header__phone-title {
  font-size: 14px;
  color: $header-font-muted-color;
  margin-bottom: 6px;
}
.site-header__phone-number {
  font-size: 20px;
  font-weight: $font-weight-bold;
}
.site-header__nav-panel {
  height: $nav-panel-height;
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
  .site-header__logo {
    width: 250px;
    justify-content: flex-start;

    //@include direction {
    //  #{$margin-inline-end}: 24px;
    //}
  }
}

.right-header-links-div {
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 0.5rem;
  font-size: 14px;
}

.right-header-links-div a {
  cursor: pointer;
  color: black !important;
}

.right-header-links-div-1 {
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  font-size: 14px;
  margin-top: 1rem;
}

.right-header-sub-links-div-1 {
  width: 70px;
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  font-size: 14px;
}
