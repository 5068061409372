// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------

@import "src/scss/themes/MyTheme";

/* Scrollbar Styling */
//::-webkit-scrollbar {
//  width: 5px;
//}
//
//::-webkit-scrollbar-track {
//  background-color: #ebebeb;
//  width: 5px;
//  -webkit-border-radius: 10px;
//  border-radius: 10px;
//}
//
//::-webkit-scrollbar-thumb {
//  -webkit-border-radius: 10px;
//  border-radius: 10px;
//  background: #6d6d6d;
//}

.cursor-pointer {
  &:hover {
    cursor: pointer!important;
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline!important;
  }
}

.container {
  max-width: 90%;
}
.text-primary {
color: $accent-color!important;
}
.btn-primary{
  &:hover{
    opacity: 0.8!important;
    background-color: $accent-color!important;
  }
}
.bg-secondary{
  background-color: $secondary-color!important;
}
.bg-primary{
  background-color: $accent-color!important;
}
.text-secondary{
  color: $secondary-color!important;

}

.DayPicker_transitionContainer {
  padding-bottom: 37px;
  box-sizing: content-box;
}

.fullscreen-modal.modal-dialog  {
  position: fixed;
  transition-duration: 2s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);

  //min-height: 70vh;
  //min-width: 50vw;
  overflow: scroll!important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-modal {
  overflow: scroll!important;
}

.modal-content {
  overflow: scroll!important;

  //width: 100%;
  //height: 100%;
background-color: #0a3b52;
  display: flex;
  justify-content: center;
  align-items: center;
}

.address-card-custom {
  @media screen and (max-width: $sm) {
    width: 90%
  }
}