/*
// .block
*/
@import "../variables";
@import "../functions";

.block {
  //margin-bottom: $block-margin-bottom;
}
.block--highlighted {
  padding: 50px 0 60px;
  background: white;
}
//
//@media (max-width: breakpoint(md-end)) {
//  .block {
//    margin-bottom: $block-margin-bottom-md;
//  }
//}
