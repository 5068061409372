/*
// .products-view
*/
@import "../variables";
@import "../mixins/card";

.products-view {
  position: relative;
}
.products-view__options {
  padding-bottom: 20px;
}
.products-view__pagination {
  margin-top: 160px;
}
.products-view__empty {
  @include card;

  padding: 84px 0;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products-view__empty-title {
  font-size: 24px;
  font-weight: $font-weight-medium;
}
.products-view__empty-subtitle {
  margin-bottom: 24px;
}
.products-view__loader {
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  z-index: 4;
  background: rgba(#fff, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.products-view--loading {
  .products-view__loader {
    opacity: 1;
    pointer-events: auto;
  }
}
