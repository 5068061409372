/*
// .mobilemenu
*/
@import "../variables";
@import "../mixins/direction";

$local-transition-duration: 0.3s;

.mobilemenu {
  visibility: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: visibility 0s $local-transition-duration;

  @include direction {
    #{$inset-inline-start}: 0;
  }
}
.mobilemenu__backdrop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: $mobilemenu-backdrop-bg;
  opacity: 0;
  transition: opacity $local-transition-duration;

  @include direction {
    #{$inset-inline-start}: 0;
  }
}

.mobilemenu__body {
  position: absolute;
  top: 0;
  width: 70vw;
  height: 100vh;
  background: $mobilemenu-bg;
  box-shadow: $mobilemenu-shadow;
  transition: transform $local-transition-duration;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  @include direction {
    #{$inset-inline-start}: 0;
    transform: translateX(
      calc(-100% * #{$transform-direction} - 30px * #{$transform-direction})
    );
  }
}

.mobilemenu__header {
  height: $mobilemenu-header-height + 1px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-bottom: 1px solid $mobilemenu-divider-color;
}
.mobilemenu__title {
  padding: 0 20px;
  font-weight: $font-weight-bold;
  flex-grow: 1;
}
.mobilemenu__close {
  border: none;
  background: transparent;
  height: $mobilemenu-header-height;
  width: $mobilemenu-header-height + 1px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: $mobilemenu-close-button-default-color;
  transition: fill 0.2s;

  @include direction {
    #{$border-inline-start}: 1px solid $mobilemenu-divider-color;
  }

  &:focus {
    outline: none;
  }
  &:focus,
  &:hover {
    fill: $mobilemenu-close-button-hover-color;
  }
}
.mobilemenu__content {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
}

.mobilemenu__footer {
  position: relative;
  bottom: 0;
}

.mobilemenu--open {
  visibility: visible;
  transition-delay: 0s;

  .mobilemenu__backdrop {
    opacity: 0.9;
  }

  .mobilemenu__body {
    @include direction {
      transform: translateX(0);
    }
  }
}
