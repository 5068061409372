@import "../variables";

.posting-success-root-container {
  margin: 80px;
}

.booking-sucess-tool-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:3%;
}

.booking-success-tool-image{
  width: 200px;
  height: 200px;
  margin-right:2%;

}
.booking-success-thankyou{
  color:$accent-color!important;
  margin-bottom: 2px;
  margin-top: 10px;
  font-weight: 500;
}

.booking-success-nessage-button{
  margin-top: 5px;
  padding: 2%;
  background-color: $accent-color;
  color:white;
  border-radius: 8px;

  &:hover{
    color:white;

  }

}

.booking-success-booking-duration{
  margin-bottom: 4%;
}