@import "../variables";

.post-tool-user-section-images {
  display: flex;
  flex-direction: row;

 @media screen and (max-width: $sm) {
   flex-direction: column;
 }
}

.post-tool-user-section-upload-image {
  width: 200px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: auto 10px;

  @media screen and (max-width: $sm) {
    width: 100%;
  }
}

.uploaded-images-section {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  //justify-content: space-evenly;

  margin-left: 8px;

  @media screen and (max-width: $sm) {
    padding-top: 15px;
    margin: auto;
    justify-content: space-between;
    width: 100%;
  }
}

.file-input{
  display: none;
}

.uploaded-images-section-item {
  width: 150px;
  height: 100px;

  border: 1px solid rgba(0, 0, 0, 0.5);
  margin: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: $sm) {
    width: 45%;
    height: 150px;
  }
}

.uploaded-images-section-item-remove {
  margin-right: 5px!important;
  cursor: pointer;

  flex: 1!important;
  align-self: flex-end;
  justify-self: flex-start;

  //background-color: rgba(255, 255, 255, 0.5);
  border-radius: 9999px;

  color: white!important;

  padding: 2px;

  display: inline-block;
}
