@import "src/scss/themes/MyTheme";
@import "../variables";

.get-paid-root-container {
  padding: 4% 4% 8%;
  border-radius: 8px;
}

.card-shadow {
  box-shadow: $box-shadow-global;
}

.rove-card {
  margin: 4% auto;

  padding: 4%;
  border-radius: 8px;
}

.balance-card-information {
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  @media screen and (max-width: $md) {
    flex-direction: column;
    justify-content: center;
  }
}

.get-paid-methods {
  width: 100%;

  .col1 {
    width: 65%;
    text-align: left;
    font-size: 1rem;
    opacity: 0.5;
  }

  .col2 {
    font-weight: 600;
    width: 25%;
    text-align: right;
    font-size: 1rem;
  }

  //tr {
  //  //border-top: 1px solid #e5e5e5;
  //  margin: 2% 0;
  //}
}

.card-extra-information {
  font-size: 0.9rem;
}

.get-paid-button {
  background-color: $accent-color !important;
  color: $accent-opposite-color !important;

  &:hover {
    background-color: $accent-color !important;
    color: $accent-opposite-color !important;

    cursor: pointer;
  }

  @media screen and (max-width: $sm){
    width: 100%;
    margin-bottom: 15px;
  }
}

.align-middle {
  margin-top: auto;
  margin-bottom: auto;
}