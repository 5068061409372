.calender-dialog-main {
  width: 95%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin-left: auto;
}

.booking-duration-dialog-header {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content:space-around;*/
  width: 100%;

  margin-top: 32px;
}

.booking-duration-dialog-title {
  position: relative;
  /*top:4%;*/
  font-size: 1.2rem;
  font-weight: 600;
  /*background: rgb(13,89,125) !important;*/
  left: 5%;
}

.relative-button {
  position: relative;
  /*top:4%;*/
  /*background: rgb(13,89,125) !important;*/
  left: 90%;
  width: 70px;
  height: 30px;
  /*color:white !important;*/
}

.sub-calender {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media (max-width: 800px) {
  .relative-button {
    position: relative;
    top: 4%;
    background: rgb(13, 89, 125) !important;
    left: 75%;
    width: 70px;
    height: 30px;
    color: white !important;
  }

  .sub-calender {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.CalendarDay__selected_span {
  background: rgb(13, 89, 125);
  color: white;
  border: 1px solid white;
}

.CalendarDay__selected {
  background: rgb(13, 89, 125);
  color: white;
}

.CalendarDay__selected:hover {
  background: rgb(13, 89, 125);
  color: white;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgb(240, 240, 240);
}

.popover-style {
  height: 500px;
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 420px;
}

.popover-style button {
  background: rgb(13, 89, 125);
  color: "white";
  margin-bottom: 1rem;
}

.reviewed-div {
  font-size: 14px;
  margin-top: -10px;
  color: rgb(201, 201, 201);
  cursor: pointer;
}

.review-form {
  height: 400px;
  width: 300px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.review-form button {
  color: white;
  background: #0d597d;

  &:hover {
    color: white;
    background: #0a3b52;
  }
}

@media (max-width: 800px) {
  .popover-style {
    height: 500px;
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 320px;
  }
}
