@import "src/scss/themes/MyTheme";
@import "../variables";

.add-payout-method {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow-x: hidden!important;
  opacity: 1;

  text-align: center;
}

.main-add-payment-method-title {
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}

.fotu {
  object-fit: contain;
}

.method-title {
  font-size: 1rem;
  font-weight: 500;
}

.method-detail {
  font-size: 0.9rem;
  line-height: 1rem;

  margin-top: 0;
  margin-bottom: 5px;
}