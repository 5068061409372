/*
// .block-slideshow
*/
@import "../variables";
@import "../functions";
@import "../mixins/buttons";
@import "../mixins/direction";

$local-dots-height: 18px;
$local-dots-padding: 4px;
$local-dot-size: $local-dots-height - $local-dots-padding * 2;

.block-slideshow__body {
}

.slick-prev {
  font-size: 30px !important;
  line-height: 0;
  z-index: 10;
  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  left: 1.2rem !important;
}
.slick-next {
  font-size: 30px !important;
  line-height: 0;
  z-index: 10;
  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 1.2rem !important;

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}
.slick-dots li button {
  text-indent: -9999px;
}
.block-slideshow {
  //margin-bottom: $block-margin-bottom;
  position: relative;

  .slick-slide > div > div {
    vertical-align: middle;

    &:focus {
      outline: none;
    }
  }

  .slick-dots {
    color: rgba(0, 0, 0, 0) !important;
    bottom: 0;
    position: absolute;
    background: $block-slideshow-dots-bg;
    padding: $local-dots-padding;
    border-radius: $local-dots-height / 2;
    list-style: none;
    margin: 0;
    width: 50px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around !important;
    background: none !important;

    @include direction {
      transform: translateX(-50% * $transform-direction);
      #{$inset-inline-start}: 50%;
    }

    li {
      display: inline-block;
    }

    button {
      width: $local-dot-size;
      height: $local-dot-size;
      padding: 0;
      border: none;
      border-radius: $local-dot-size / 2;
      background: lightgray;

      &:focus {
        outline: none;
      }

      &:hover {
        background: $block-slideshow-dot-hover-color;
      }
    }
    .slick-active button {
      background: white;
    }

    li + li {
      margin-left: 6px;
    }
  }
}
.block-slideshow__slide {
  position: relative;
  display: block;
  color: inherit;
  vertical-align: middle;

  &:hover {
    color: inherit;
  }
}

@mixin overlay($bgColor: #000, $bgOpacity: 0.6) {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($bgColor, $bgOpacity);
    z-index: 0;
  }

  .row {
    position: relative;
    z-index: 2;
  }
}
$color-beta: #000;

.block-slideshow__slide-image {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: $secondary-color;
  @include overlay($color-beta, 0.1);
}

.block-slideshow__slide-content {
  position: absolute;
  bottom: 46px;

  @include direction {
    #{$inset-inline-start}: 46px;
  }
}
.block-slideshow__slide-title {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: $font-weight-bold;
  margin-bottom: 12px;
  opacity: 0;
  transition: all 1s 0.2s;
  color: white;
}
.block-slideshow__slide-text {
  font-size: 1.5rem;
  line-height: 1.2;
  opacity: 0;
  margin-top: 25px;
  transform: translateY(15px);
  transition: all 0.8s 0.5s;
  color: white;
}

.block-slideshow__slide-button___button {
}

.block-slideshow__slide-button {
  margin-top: 15px;
}
.block-slideshow .correct-slick-active {
  .block-slideshow__slide-title,
  .block-slideshow__slide-text,
  .block-slideshow__slide-button {
    opacity: 1;
    transform: none;
  }
}

.block-explore-btn {
  color: white;
  background: $accent-color;

  padding-right: 50px;
  padding-left: 50px;

  &:hover {
    color: white;
  }
}

.block-slideshow--layout--full {

  @media (min-width: breakpoint(md-start)) {
    .block-slideshow__body,
    .block-slideshow__slide {
      height: 60vh;
      background-color: $secondary-color;
    }

    .block-slideshow__slide-content {
      bottom: 54px;

      @include direction {
        #{$inset-inline-start}: 72px;
      }
    }
    .block-slideshow__slide-title {
      margin-bottom: 16px;
      line-height: 36px;
    }
    .block-slideshow__slide-button {
      margin-top: 15px;
    }
  }

  .block-slideshow__slide-image--desktop {

    background-size: cover;
    @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
      background-position: -70px top;
    }

    @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
      background-position: -190px top;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  .block-slideshow__slide-image--mobile {
    display: none;

    @media (max-width: $sm) {
      background-position: top center;
      background-size: cover;
      display: block;
    }
  }
}

.block-slideshow--layout--with-departments {
  $local-margin: 15px;

  .block-slideshow__body {
    margin-top: $local-margin;
    height: 500px - $local-margin;
  }
  .block-slideshow__slide {
    height: 500px - $local-margin;
  }

  @media (min-width: breakpoint(lg-start)) {
    .block-slideshow__body {
      @include direction {
        #{$margin-inline-start}: (30px - $local-margin) * -1;
      }
    }
  }

  @media (max-width: breakpoint(md-end)) {
    .block-slideshow__slide-button .btn {
      @include btn-sm();
    }
  }
}

@media (max-width: $sm) {
  .block-slideshow__body, .block-slideshow__slide {
    height: 52vh;
  }
  .block-slideshow__slide-content {
    top: 5rem;
    left: 10px!important;
    position: absolute;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    //height: 100px;
    margin: auto;
    //border: 2px solid red;

    @include direction {
      #{$inset-inline-start}: 5%;
      #{$inset-inline-end}: 5%;
    }
  }
  .block-slideshow__slide-title {
    font-size: 3rem;
  }
  .block-slideshow__slide-text {
    font-size: 1.2rem;
    margin-top: 0;
  }
  .block-slideshow__slide-button {
    margin-top: 15px;
  }
  .block-slideshow__slide-button .btn {
    @include btn-sm();
  }
}