/*
// .site-footer
*/
@import "../variables";
@import "../functions";

.site-footer {
  margin-top: 0px;
  background: $footer-bg;
  border-top: $footer-border-top;
}

.site-footer__logo {
  width: 12rem;
  margin: auto auto;
}

.site-footer__logo_img {
  width: 100%;
  margin-bottom: 1rem;
}

.footer-links__tagline {
  color: $accent-color;
}

.site-footer__widgets {
  padding: 48px 0 46px;
}
.site-footer__bottom {
  height: 54px;
  border-top: $footer-copyright-border;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.site-footer__copyright {
  font-size: 14px;

  display: flex;
  justify-content: space-between;

  width: 100%;

  a:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: $sm) {
    flex-direction: column;
  }
}

.site-footer-toology {
  display: inline-block;

  text-align: left;
  text-transform: capitalize;

  @media screen and (max-width: $sm) {
    text-align: center;
  }
}

.site-footer-managed {
  display: inline-block;

  text-align: right;
  text-transform: capitalize;

  @media screen and (max-width: $sm) {
    text-align: center;
    margin-top: 5px;
  }
}

.site-footer__payments {
  img {
    max-width: 100%;
  }
}
.site-footer__widget {
  & + & {
    margin-top: 24px;
  }
}

@media (max-width: breakpoint(sm-end)) {
  .site-footer__widgets {
    padding-bottom: 48px;
  }

  .site-footer__bottom {
    padding: 20px 0 24px;
    flex-wrap: wrap;
    height: auto;
  }

  .site-footer__payments {
    order: -1;
  }

  .site-footer__copyright + .site-footer__payments {
    margin-bottom: 24px;
  }

  .site-footer__copyright,
  .site-footer__payments {
    width: 100%;
    text-align: center;
  }
}
