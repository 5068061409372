@import "src/scss/themes/MyTheme";
@import "../variables";
.main-container {
  margin: 1.5% 0 0;
  padding: 0 1% 0;
  height: 75vh;
  display: flex;
  overflow: hidden;
  background-color: white;

  @media screen and (max-width: $lg) {
    margin: 0;
    height: 85vh;
    padding-bottom: 0;
  }
}

.side-bar {
  width: 25%;
  max-height: 74vh;
  overflow-y: hidden;
  padding: 0 2px 0;
  align-items: center;
  //@media screen and (max-width: $lg) {
  //    width: 40%;
  //}

  @media screen and (max-width: $lg) {
    width: 100%;
    max-height: 100vh;
  }

  .scroll {
    overflow-y: scroll;
  }
}

.conversation-items-list {
  overflow-y: auto;
  overflow-x: hidden !important;
  width: 100%;
  height: 90%;
  padding: 0 0 15%;
}

.search-field {
  display: flex;

  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  height: 2.5rem;

  border-radius: 0.5rem;
  background-color: #eeeeee;

  align-items: center;
}

.search-text-field {
  color: $text-color;
}

.search-text-field > input {
  color: $text-color;
  margin-left: 10px;
  margin-right: 10px;
}

.search-icon {
  padding: 4px 4px 4px 12px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.conversation-item-container {
  cursor: pointer;
  padding: 20px 8px 0;
}

.active-conversation{
  background: $accent-color!important;
  color:white!important;
}

.active-conversation-sender{
  color:white!important;
}
.active-conversation-user-photo{
  background-color: $secondary-color!important;
  color:$accent-color!important;
}

.conversation-item {
  //height: 4rem!important;
  padding: 16px 16px 8px;
  margin-bottom: 8px;
  border-width: 1px;
  box-shadow: $box-shadow-global;
  border-radius: 12px;
  display: flex;
  align-items: start;

  &:hover {
    background-color: #eee;

    .conversation-date {
      color: #1b1c1e;
    }
  }
}

.user-photo-text{
  font-weight: 800;
  text-transform: uppercase;

}

.user-photo {
  border: 1px solid $secondary-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.7rem!important;
  width: 2.7rem!important;
  color: #fdc126;
  border-radius: 1.25rem;
  background: $accent-color;

}

hr {
  margin: 0;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.conversations-container {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  @media screen and (max-width: $lg) {
    width: 100%;
    overflow: hidden;
  }
}

.conversations-container-main {
  padding: 16px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: static;
}

.conversation-text {
  width: 85%;
}

.conversation-sender {
  font-weight: 800;
  font-size: 0.9rem;
  color: #0d597d;
  margin-bottom: 1px;
  margin-top: 2px;
  width: 75%;

}

.conversation-message {
  font-weight: 100;
  font-size: 0.8rem;
  margin-bottom: 3px;
  padding-top: 2px;
  padding-left: 2px;
  width: 75%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: $md) {
    width: 100%;
  }
}

.conversation-date {
  font-weight: 800;
  font-size: 0.7rem;
  width: 100%;
  text-align: right;
  color: #9e9e9e;
  padding-top: 5px;
}

.user-title {
  font-weight: 800;
  font-size: 1.2rem;
  text-align: right;
  margin: 0 0 0 8px;

  align-self: center;

  display: inline-block;
}

.conversation-header {
  text-align: right;
  width: 100%;
  height: 5rem;
  padding: 0 10px 8px;
  //background-color: #eee;
  box-shadow: $box-shadow-global;
  color: $accent-color;
  vertical-align: middle;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.back-button {
  display: inline-block;
}

.left-stufff {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.conversion-search {
  align-self: end;
  width: 30%;
  height: fit-content;
}

.input-bar {
  width: 100%;

  position: relative;
  bottom: 0;

  padding: 15px 30px;
  height: fit-content;
  max-height: 30% !important;

  overflow-y: hidden;
  overflow-x: hidden !important;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-end;
  box-shadow: $box-shadow-global;
  background-color: #f6f6f6;
}

.input-bar-text-field {
  overflow-y: auto;
  border-width: 0;
  border-radius: 5px;

  padding: 8px 15px;
  margin-right: 15px;

  width: 100%;
  min-height: 5ch;
  max-height: 30ch !important;

  resize: none !important;

  textarea:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
    border: 1px dashed red !important;
  }
  &:focus {
    outline: none;
  }
}
.input-bar-send-button {
  width: 100% !important;
  height: 100% !important;
  color: $text-inverted-color !important;
  background: $accent-color !important;

  :hover {
    color: #ffffff !important;
  }
}
.disabled-icon{
  opacity: 0.3;
  color: #0d597d!important;

}
.input-bar-send-icon {
  color: #0d597d!important;
  font-size: 2.5rem!important;
  cursor: pointer;
  align-content: end;
  text-align: right;
  opacity: 1;
}

.message-bubble {
  //display: inline-block;

  margin: 5px 2px;
  padding: 10px 10px;

  height: fit-content;
  min-width: fit-content;
  max-width: 60%;
  color: #f6f6f6;
  border-radius: 5px;

  word-wrap: break-word;
}

.message-received {
  text-align: left;
  align-self: flex-start;
  color: $text-color;
  background-color: #eee;
}

.message-sent {
  background-color: #0d597d;
  color: $text-inverted-color;
  text-align: right;
  align-self: flex-end;
}

.message-text {
  //width: 100%;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: left;
  display: inline;
}

.message-date {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 8px;
  font-size: 0.6rem;
  margin-bottom: -5px;
  text-align: right;
  /*display: inline-block;*/
  /*margin-left: 10px;*/
}
.message-date-received{
  color:black!important;
}