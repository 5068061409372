@import "src/scss/themes/MyTheme";
@import "../variables";

.checkout-root-container {
  border-radius: 8px;

}

.checkout-header-image{

}

.shadow-div {
  box-shadow: $box-shadow-global;
}

.checkout-header {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.close-button {
  font-weight: 500;

  background-color: rgba(0, 0, 0, 0);
  color: $text-color;

  text-transform: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: $secondary-color;
  }
}

.checkout-container-main {
  padding-top: 20px;

  .checkout-heading-main {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0;

    width: 100%;
  }
}
.checkout-notice-terms {
  font-size: 0.9rem;
  font-weight: 300;
}

.rental-summary-column {
  //width: 35%;
  //padding: 3% 3% 2%;
  //border-radius: 8px;
  //height: 30%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;


  .title-is {
    align-self: center;
    margin-bottom: 3rem;

    text-transform: capitalize;
  }
}

.summary-image {
  width: 100%;
  align-self: center;
  margin-bottom: 3rem;
}

.tool-summary-deets {
  color: $accent-color;
}

//.checkout-information-column {
//  width: 65%;
//  padding: 3% 3% 2%;
//  border-radius: 8px;
//  height: auto;
//}

.checkout-user-information-name {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}
.checkout-user-information-name-field {
  width: 100% !important;
  margin: 8px;
}

.checkout-address-card-container {
  display: flex !important;
  flex-direction: row !important;

  flex-wrap: wrap;

  overflow: hidden;
}

.payment-model-billings-form {
  //margin-left: 30px;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  //align-items: center;
  //min-height: 80vh;

  color: #0d597d;
  font-weight: 500;
}

.inputandlabeldiv {
  display: flex;
  flex-direction: column;
  width: 100%;

  input::placeholder {
    color: #2490c2;
    font-weight: 200;
    font-size: 14px;
  }
}

.expiry-cvc {
  margin-top: 15px;
  width: 100% !important;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
}

.half-field {
  width: 45%;

  input {
    width: 100%;

    ::placeholder {
      color: #2490c2;
      font-weight: 200;
      font-size: 14px;
    }
  }
}

.add-card-details {
  display: flex;
  flex-direction: row;
}

.disabled{
  opacity: 0.5;
}

.add-card-button {
  width: 100%;

  background-color: $accent-color !important;
  color: $accent-opposite-color !important;

  align-self: flex-end;

  &:hover {
    background-color: $accent-color !important;
    color: $accent-opposite-color !important;
  }
}

.saved-cards {
  display: flex;
  flex-direction: co;

  align-items: end;

  margin: 15px 0 30px 0;
}

.card-number {
  margin: 0 0 0 10px;
  font-size: 0.8rem;
}

.change-card {
  display: inline-block;

  //flex: 1;
  justify-self: flex-end;

  text-align: right;

  margin-bottom: 0;

  color: $link-color;

  font-size: 0.8rem;
  margin-left: 15px;

  &:hover {
    color: $link-hover-color;
    cursor: pointer;
  }
}

.visa-logo {
  width: 2rem;
}

.checkout-tool-detail {
  margin-top: 10px;
}

.checkout-information-card-container {
  width: 100%;

  display: flex;
  flex-direction: row;

  overflow: hidden;
}

.checkout-product-image {
  object-fit: cover;
  width: 180px;
  height: 180px;
}

.checkout-tool-description {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 30px;
  width: 70%;
  align-self: center;
}

.checkout-payment-column {
  //height: 100%;
  width: 100%;
}

.checkout-summary {
  display: flex !important;
  flex-direction: column !important;
  //padding: 0 5%;
  border-radius: 8px;
}

.checkout-order-button {
  background-color: $accent-color !important;
  color: $accent-opposite-color !important;
  width: 100%;

  margin-bottom: 10px !important;

  &:hover {
    background-color: $accent-color !important;
    color: $accent-opposite-color !important;
  }
}

#card-element {
  width: 80%;
}

.order-summary {
  width: 100%;
  margin: 0;

  .col1 {
    width: 65%;
    text-align: left;
    font-size: 1rem;
    opacity: 1;
    font-weight: 800;
  }

  .col2 {
    font-weight: 600;
    width: 25%;
    text-align: right;
    font-size: 1rem;
  }

  .order-total {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.select-payment-method-container {
  margin: 15px 0;
}

.payment-radio-button{
  font-size: 1rem;

}

.post-tool-condition-radio-button{
  font-weight: 600!important;
  text-align: center;
  align-items: center;
  justify-content: center;
  color:$accent-color!important;
  //margin-top: 15%;
  margin-right: 16px;
}

.checkout-form-field {
  display: flex;
  flex-direction: column;

  width: 100%;

  color: $text-color;

  font-size: 0.9rem;
  font-weight: 200;

  & > input {
    ::placeholder {
      opacity: 0.1;
    }

    .checkout-form-control {
    }
  }
}

.paypal-button-checkout{

}

tr {
  border: none !important;
  height: fit-content !important;
}

td {
  border: none !important;
  padding-bottom: 0 !important;
}

#checkout-promo-code {
  tr {
    #clear-promo-code {
      width: 15% !important;
    }

    #promo-code-input {
      width: 65% !important;
    }

    #apply-promo-code {
      width: 20% !important;
    }
  }
}

.popover {
  @media screen and (min-width: $sm) {
    max-width: 700px !important;
    margin-left: 2% !important;
  }
}