/*
// .not-found
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.not-found {
  text-align: center;
}
.not-found__404 {
  font-size: 80px;
  font-weight: $font-weight-bold;
  color: $body-divider-color;
  padding: 40px 0 20px;
}
.not-found__content {
  width: 480px;
  max-width: 100%;
  margin: 0 auto;
}
.not-found__title {
  margin-bottom: 30px;
}
.not-found__text {
  margin-bottom: 20px;
}
.not-found__search {
  display: flex;
  margin-bottom: 30px;
}
.not-found__search-input {
  width: 1px;
  flex-grow: 1;

  @include direction {
    #{$margin-inline-end}: 10px;
  }
}

@media (max-width: breakpoint(md-end)) {
  .not-found__404 {
    font-size: 60px;
  }
}
@media (max-width: breakpoint(sm-end)) {
  .not-found__404 {
    font-size: 50px;
    line-height: 54px;
    padding: 40px 0 32px;
  }
}
