/*
// .spec
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.spec {
  font-size: 15px;
  line-height: 18px;
}
.spec__header {
  margin-bottom: 50px;
}
.spec__section {
  & + & {
    margin-top: 40px;
  }
}
.spec__section-title {
  font-weight: $font-weight-medium;
  font-size: 18px;
  letter-spacing: -0.03em;
  line-height: 20px;
  margin: 0 0 12px;
}
.spec__row {
  padding: 8px 12px;
  display: flex;
  border-bottom: 1px solid $card-divider-color;

  &:first-of-type {
    border-top: 1px solid $card-divider-color;
  }
}
.spec__name {
  width: 230px;
  flex-shrink: 0;
  color: $card-font-muted-color;

  @include direction {
    #{$padding-inline-end}: 30px;
  }
}
.spec__disclaimer {
  margin-top: 40px;
  font-size: 14px;
  line-height: 22px;
  color: $card-font-muted-color;
}

@media (min-width: breakpoint(sm-start)) and (max-width: breakpoint(sm-end)) {
  .spec__name {
    width: 170px;
  }
}
@media (max-width: breakpoint(xs-end)) {
  .spec__header {
    margin-bottom: 30px;
  }
  .spec__row {
    display: block;
    padding: 12px 0;
  }
  .spec__name {
    width: auto;
    margin-bottom: 6px;
  }
}
