/*
// .contact-us
*/
@import "../variables";
@import "../functions";

.help-card {
  width: 19rem;
  height: 5rem;

  padding: 5px;
  margin: 10px 5px;

  @media screen and (max-width: $sm) {
    height: fit-content !important;

    margin: 0 auto;
  }
}
