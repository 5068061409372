@import "../variables";

.post-tool-containerr {
  width: 75%;

  @media screen and (max-width: $sm) {
    width: 100%;
  }
}

.post-tool-add-price {
  width: 50%;

  @media screen and (max-width: $sm) {
    width: 100%;
    margin-bottom: 15px;
  }
}

.revieww-imagee {
  width: 300px;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: $sm) {
    width: 100%;
  }
}